import React from "react";
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Chat, Security, VerifiedUser } from "@material-ui/icons";
// core components
const GridContainer = dynamic(() => import('/components/Grid/GridContainer.js'));
const GridItem = dynamic(() => import('/components/Grid/GridItem.js'));
const InfoArea = dynamic(() => import('/components/InfoArea/InfoArea.js'));

import styles from "/styles/jss/bbhost/pages/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);
import { trans, getLangFromObj } from "lang/lang.js";
import { OUR_DIFFERENCES } from "configs/index";

export default function OurDifferencesSection(props) {
  const classes = useStyles();
  const { locale } = useRouter();
  const differences = OUR_DIFFERENCES.map((contact, key) => {
    let icon = (<></>);

    if(typeof contact.text === "object")
    {
        contact.text = getLangFromObj(contact.text, locale);
    }
    if(typeof contact.subText === "object")
    {
        contact.subText = getLangFromObj(contact.subText, locale);
    }
    
    if(contact.icon === "chat")
        icon = Chat;
    if(contact.icon === "security")
        icon = Security;
    if(contact.icon === "verifieduser")
        icon = VerifiedUser;

    return (
        <GridItem key={key} xs={12} sm={12} md={4}>
            <InfoArea
            title={contact.text}
            description={contact.subText}
            icon={icon}
            iconColor={contact.iconColor}
            vertical
            />
        </GridItem>
    );
  });
  
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{trans('index::differential_section.title')}</h2>
          <br/>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          {differences}
        </GridContainer>        
      </div>
    </div>
  );
}
